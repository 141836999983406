import axios from 'axios';
import { store } from 'reducers';
import { coreuiAction } from 'actions/coreui';
import { profileAction } from 'actions/profile';
import { stringify } from 'query-string';
import { API_URL } from 'env';

const onError = ({ response }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  if (response) {
    const { status, statusText, data } = response;
    if (status === 401) {
      profileAction.signOut();
    } else {
      const { message = `${status} - ${statusText}` } = data;
      coreuiAction.enqueueSnackbar(message.charAt(0).toUpperCase() + message.slice(1), {
        variant: 'error',
        key: 'axios',
      });
    }
  } else {
    coreuiAction.enqueueSnackbar(`Cannot connect to Server`, { variant: 'error', key: 'axios' });
  }
  return Promise.reject(response);
};

const beforeRequest = (config) => {
  const { isLoggedIn, api, token } = store.getState().profile;
  if (isLoggedIn) {
    Object.assign(config.headers, { Authorization: `Bearer ${token}` });
    config.url = config.url.replace('/v1', api);
  }
  if (config.data instanceof FormData) {
    Object.assign(config.headers, { 'Content-Type': 'multipart/form-data' });
  }
  return config;
};

const client = axios.create({
  baseURL: API_URL,
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'index' }),
});
client.interceptors.request.use(beforeRequest);

[client].forEach((client) => {
  client.interceptors.response.use(({ data: response }) => {
    const { status = 1, data, errors } = response;
    if (status) return data;
    else return Promise.reject(errors);
  }, onError);
});

const clientRaw = axios.create({
  baseURL: API_URL,
  paramsSerializer: (params) => stringify(params, { arrayFormat: 'index' }),
});
clientRaw.interceptors.request.use(beforeRequest);

export { client, clientRaw };
