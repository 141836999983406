import { SalesInvoice } from 'views/SalesInvoice';
import { ArtistApprove } from 'views/ArtistApprove';
import { UserList } from 'views/User';
import { FeeStructure } from 'views/FeeStructure';
import { ImportNFT } from 'views/ImportNFT';
import { Profile } from 'views/Profile';

const privateRoute = {
  home: {
    path: '/sales-summary',
    component: SalesInvoice,
    name: 'Sales Summary',
  },

  salesSummary: {
    path: '/sales-summary',
    component: SalesInvoice,
    name: 'Sales Summary',
  },

  importNFT: {
    path: '/import-NFT',
    component: ImportNFT,
    name: 'Import NFT',
  },

  contractWhitelist: {
    path: '/import-NFT/contractWhitelist',
    component: ImportNFT,
    name: 'Contract Whitelist',
    title:
      'Whitelist contract addresses to scan NFTs in User’s wallet and import the available NFTs to Spores Marketplace',
  },

  artistCreatorWhitelist: {
    path: '/import-NFT/artistCreatorWhitelist',
    component: ImportNFT,
    name: 'Artist/Creator Whitelist',
    title:
      'Whitelist Artist/Creator’s wallet address to scan all their NFTs and show them on Artist/Creator’s User Profile.',
  },

  deListNFT: {
    path: '/import-NFT/deListNFT',
    component: ImportNFT,
    name: 'DeList NFT',
    title: 'Delist unqualified NFT from Spores Marketplace',
  },

  artistApprove: {
    path: '/artists-approval',
    component: ArtistApprove,
    name: 'Artist Approval',
  },

  users: {
    path: '/users',
    component: UserList,
    name: 'Users',
  },

  configuration: {
    path: '/configurations',
    component: FeeStructure,
    name: 'Configuration',
  },

  profile: {
    path: '/profile',
    component: Profile,
  },
};

export default privateRoute;
