import React from 'react';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { InputNumberFormat, Spinner } from 'components';
import { Paper, TableCell, TableRow, Typography, TableHead, Table, TableBody, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { queryClient } from 'services/client';
import { marketService } from 'services/market';
import { Big } from 'big.js';

const FeeStructure = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { control, handleSubmit, setValue } = useForm({ mode: 'onChange' });
  const { mutate: updateFeeStructure, isLoading } = useMutation(marketService.updateFeeStructure, {
    onSuccess: () => {
      enqueueSnackbar('Update fee structure successfully', { variant: 'success' });
      queryClient.invalidateQueries('marketService.fetchFeeStructure');
    },
  });

  const { data, isFetching } = useQuery(['marketService.fetchFeeStructure'], () => marketService.fetchFeeStructure());
  const { fees: dataList = [] } = data;

  const toPercentage = (value) => {
    return parseFloat(Big(value).mul(100).valueOf());
  };

  const offPercentage = (value) => {
    return parseFloat(Big(value).div(100).valueOf());
  };

  const handleClickSave = () => {
    handleSubmit((values) => {
      updateFeeStructure(
        dataList.map(({ categoryID }) => ({
          categoryID,
          primaryFee: offPercentage(values['primaryFee-' + categoryID]),
          secondaryFee: offPercentage(values['secondaryFee-' + categoryID]),
          royaltyFee: offPercentage(values['royaltyFee-' + categoryID]),
        })),
      );
    })();
  };

  React.useEffect(() => {
    dataList.forEach((item) => {
      setValue('primaryFee-' + item.categoryID, toPercentage(item.primaryFee));
      setValue('secondaryFee-' + item.categoryID, toPercentage(item.secondaryFee));
      setValue('royaltyFee-' + item.categoryID, toPercentage(item.royaltyFee));
    });
  }, [setValue, dataList]);

  return (
    <Paper className='p-6'>
      <Typography variant='h4' className='mb-1'>
        Fee structure
      </Typography>
      <Typography className='mb-6'>
        Set up the fee structure for Primary and Secondary sales that applies to all common Artists / Creators on Spores
        Marketplace.
      </Typography>

      <Spinner spinning={isFetching || isLoading}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell rowSpan={2}>Product Line</TableCell>
              <TableCell align='center'>Primary Sale</TableCell>
              <TableCell align='center' colSpan={2}>
                Secondary Sale
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='center'>Commission Fee (%)</TableCell>
              <TableCell align='center'>Commission Fee And Royalty Fee (%)</TableCell>
              <TableCell align='center'>Note (Royalty Fee %)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.category.name}</TableCell>
                <TableCell className='text-center'>
                  <Controller
                    name={'primaryFee-' + item.categoryID}
                    control={control}
                    render={({ field }) => <TextField {...field} InputProps={{ inputComponent: InputNumberFormat }} />}
                  />
                </TableCell>
                <TableCell className='text-center'>
                  <Controller
                    name={'secondaryFee-' + item.categoryID}
                    control={control}
                    render={({ field }) => <TextField {...field} InputProps={{ inputComponent: InputNumberFormat }} />}
                  />
                </TableCell>
                <TableCell className='text-center'>
                  <Controller
                    name={'royaltyFee-' + item.categoryID}
                    control={control}
                    render={({ field }) => <TextField {...field} InputProps={{ inputComponent: InputNumberFormat }} />}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Spinner>

      <div className='mt-6'>
        <LoadingButton className='w-24' loading={isLoading} onClick={handleClickSave}>
          Save
        </LoadingButton>
      </div>
    </Paper>
  );
};

export default FeeStructure;
