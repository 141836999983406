import React from 'react';
import { Paper, FormControl, TextField, Button, InputBase, Table, TableHead, TableBody, Dialog } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { styled, alpha } from '@mui/material/styles';
import SearchIcon from '@mui/icons-material/Search';
import { Spinner } from 'components';
import { TableRowCustom, TableCellCustom } from '../ContractWhitelist/ContractWhislist';
import RestoreIcon from '@mui/icons-material/Restore';
import { usePopup } from 'hooks';
import { PopupDeList } from 'views/Checkout';

function DeListNFT() {
  const { control } = useForm({
    mode: 'onChange',
    defaultValues: { url: '' },
  });
  const [isOpenPopup, onOpenPopup, onClosePopup] = usePopup();
  return (
    <div>
      <Paper className='mb-4 rounded-lg p-8'>
        <div className='flex gap-4'>
          <Controller
            name='url'
            defaultValue={''}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField placeholder='Enter Item’s URL on Spores Marketplace' {...field} />
              </FormControl>
            )}
          />
          <Button startIcon={<AddIcon />}>Preview</Button>
        </div>
      </Paper>
      <Paper className='mb-4 rounded-lg p-8' style={{ minHeight: '500px' }}>
        <Controller
          name='url'
          defaultValue={''}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <Search>
                <SearchIconWrapper>
                  <SearchIcon />
                </SearchIconWrapper>
                <StyledInputBase placeholder='Search items' inputProps={{ 'aria-label': 'search' }} />
              </Search>
            </FormControl>
          )}
        />
        <div style={{ overflowX: 'auto' }} className='mt-6'>
          <Spinner spinning={false}>
            <Table>
              <TableHead>
                <TableRowCustom>
                  <TableCellCustom>ITEM</TableCellCustom>
                  <TableCellCustom style={{ width: '20%' }}>PREVIEW</TableCellCustom>
                  <TableCellCustom style={{ width: '13%' }}>
                    <span style={{ paddingLeft: '12px' }}>ACTIONS</span>
                  </TableCellCustom>
                </TableRowCustom>
              </TableHead>
              <TableBody>
                {[1, 2, 3, 4, 5, 6].map((item) => (
                  <TableRowCustom>
                    <TableCellCustom>
                      https://spores.app/explore/yrhjvvnvsx-9703-4bb6-a0b7-97sxcbvdf2sfs
                    </TableCellCustom>
                    <TableCellCustom>
                      <img
                        className='max-h-10'
                        src='https://dictionary.cambridge.org/vi/images/thumb/cat_noun_001_02368.jpg?version=5.0.204'
                      />
                    </TableCellCustom>
                    <TableCellCustom>
                      <ButtonReList startIcon={<RestoreIcon />} onClick={onOpenPopup}>
                        Re-list
                      </ButtonReList>
                    </TableCellCustom>
                  </TableRowCustom>
                ))}
              </TableBody>
            </Table>
          </Spinner>
        </div>
      </Paper>
      <Dialog open={isOpenPopup} maxWidth='xl'>
        <PopupDeList onClose={onClosePopup} />
      </Dialog>
    </div>
  );
}

const ButtonReList = styled(Button)({
  background: 'none!important',
  border: 'none',
  minWidth: '100px',
});

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginRight: theme.spacing(0),
  marginLeft: 0,
  width: '100%',
  border: '2px solid #DFE0E6',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(0),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
}));

export default DeListNFT;
