import { client, clientRaw } from './axios';

const fetchTreasureWalet = () => client.get(`/v1/nft/sales/treasure-address`);
const searchSales = (params) => client.get(`/v1/nft/sales`, { params });
const downloadSales = (params) => clientRaw.get(`/v1/nft/sales`, { params, responseType: 'arraybuffer' });

const fetchFeeStructure = () => client.get(`/v1/nft/fees`);
const updateFeeStructure = (body) => client.put(`/v1/nft/fees`, body);

export const marketService = {
  fetchTreasureWalet,
  searchSales,
  downloadSales,

  fetchFeeStructure,
  updateFeeStructure,
};
