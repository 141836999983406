import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { Button, FormControl, TextField } from '@mui/material';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { ButtonCustom, TableCellCustom, TableRowCustom } from './ContractWhislist';
import { useToggle } from 'hooks';
import { storageService } from 'services/storage';

function ContractTableRow({ contractAddr, source, onOpenSave, onOpenDelete }) {
  const { control, handleSubmit } = useForm({ mode: 'onChange' });
  const [openEdit, toggleOpenEdit] = useToggle(false);
  const handleClickSubmit = () => {
    onOpenSave();
    storageService.saveContract(contractAddr);
    handleSubmit((values) => {
      storageService.saveSource({ source: values.source });
    })();
  };

  const handleClickDelete = () => {
    storageService.saveContract(contractAddr);
    onOpenDelete();
  };

  return (
    <>
      <TableRowCustom>
        <TableCellCustom>{source}</TableCellCustom>
        <TableCellCustom>{contractAddr}</TableCellCustom>
        <TableCellCustom>
          <ButtonCustom startIcon={<EditOutlinedIcon />} onClick={toggleOpenEdit}>
            Edit
          </ButtonCustom>
          <ButtonCustom
            startIcon={<DeleteOutlinedIcon style={{ color: '#FF5630' }} />}
            style={{ color: '#FF5630' }}
            onClick={handleClickDelete}
          >
            Delete
          </ButtonCustom>
        </TableCellCustom>
      </TableRowCustom>
      {openEdit && (
        <TableRowCustom>
          <TableCellCustom>
            <Controller
              name='source'
              defaultValue={source}
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <TextField placeholder='Enter Source Name' {...field} />
                </FormControl>
              )}
            />
          </TableCellCustom>
          <TableCellCustom>
            <Controller
              name='address'
              defaultValue={contractAddr}
              control={control}
              render={({ field }) => (
                <FormControl fullWidth>
                  <TextField placeholder='Enter Contract Address' inputProps={{ readOnly: true }} {...field} />
                </FormControl>
              )}
            />
          </TableCellCustom>
          <TableCellCustom>
            <Button style={{ color: '#17204D' }} startIcon={<CheckIcon />} onClick={handleClickSubmit}>
              Save
            </Button>
          </TableCellCustom>
        </TableRowCustom>
      )}
    </>
  );
}

export default ContractTableRow;
