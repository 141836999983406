import { Typography } from '@mui/material';
import React from 'react';

const UserList = () => {
  return (
    <div className='mx-auto'>
      <Typography variant='h4' className='text-neutral-100'>
        This feature is currently in development
      </Typography>
    </div>
  );
};

export default UserList;
