import { Backdrop, CircularProgress } from '@mui/material';

const Spinner = ({ spinning, icon = <CircularProgress />, children }) => {
  return (
    <div className='relative'>
      {children}
      <Backdrop
        open={spinning}
        sx={{
          position: 'absolute',
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: 'rgba(18, 18, 18, 0.1)',
        }}
      >
        {icon}
      </Backdrop>
    </div>
  );
};

export default Spinner;
