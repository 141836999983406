import React from 'react';
import { useSnackbar } from 'notistack';
import { useMutation, useQuery } from 'react-query';
import { EnhancedTable, Spinner } from 'components';
import { LoadingButton } from '@mui/lab';
import { Button, Dialog, DialogActions, InputAdornment, Paper, TextField, Typography } from '@mui/material';
import { SearchOutlined } from '@mui/icons-material';
import { queryClient } from 'services/client';
import { accountService } from 'services/account';
import debounce from '@mui/utils/debounce';

const ArtistApprove = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [inputValue, setInputValue] = React.useState('');

  const [isOpenDialog, setIsOpenDialog] = React.useState(false);
  const [chosenDialog, setChosenDialog] = React.useState({});

  const { data: countCreator, refetch: refetchCountCreator } = useQuery(['accountService.countCreator'], () =>
    accountService.countCreator(),
  );
  const { data: countUser, refetch: refetchCountUser } = useQuery(['accountService.countUser'], () =>
    accountService.countUser(),
  );

  const { data, isFetching, refetch } = useQuery(
    ['accountService.searchUser'],
    () => accountService.searchUser({ search: inputValue }),
    { enabled: !!inputValue },
  );
  const { creators: dataList = [] } = data;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceChange = React.useCallback(debounce(refetch, 300), []);

  const { mutate: updateUserQualified, isLoading: isLoadingConfirm } = useMutation(
    (body) => accountService.updateUserQualified(body),
    {
      onSuccess: () => {
        enqueueSnackbar('Update creator successfully', { variant: 'success' });
        setIsOpenDialog(false);

        refetchCountCreator();
        refetchCountUser();
        refetch();
      },
    },
  );

  const { mutate: updateUserNote } = useMutation((body) => accountService.updateUserNote(body), {
    onSuccess: () => {
      queryClient.invalidateQueries('accountService.searchUser');
    },
  });

  return (
    <Paper className='p-6'>
      <div className='flex items-center mb-6'>
        <TextField
          fullWidth
          placeholder='Search wallet address'
          value={inputValue}
          onChange={(event) => {
            setInputValue(event.target.value);
            debounceChange();
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <SearchOutlined />
              </InputAdornment>
            ),
          }}
        />
        <div className='w-10' />
        <div className='flex items-baseline'>
          <Typography variant='h5' className='mr-1'>
            {countCreator.count}
          </Typography>
          <Typography>Creators</Typography>
          <div className='w-4' />
          <Typography variant='h5' className='mr-1'>
            {countUser.count}
          </Typography>
          <Typography>Users</Typography>
        </div>
      </div>

      <Spinner spinning={isFetching} icon={false}>
        <EnhancedTable
          data={dataList.filter((item) => item.address === inputValue)}
          columns={[
            { name: 'username', label: 'NAME', options: { setCellProps: () => ({ className: 'font-medium' }) } },
            { name: 'address', label: 'WALLET' },
            {
              name: 'note',
              label: 'NOTE',
              options: {
                customBodyRender: (value, { rowIndex }) => {
                  const item = dataList[rowIndex];
                  return (
                    <TextField
                      fullWidth
                      defaultValue={value}
                      onBlur={(event) => {
                        const { value } = event.target;
                        if (item.note !== value) {
                          updateUserNote({ id: item.address, note: value });
                        }
                      }}
                    />
                  );
                },
              },
            },
            {
              name: 'actions',
              label: 'ACTIONS',
              options: {
                setCellHeaderProps: () => ({ className: 'text-center' }),
                setCellProps: () => ({ className: 'text-center' }),
                customBodyRender: (value, { rowIndex }) => {
                  const item = dataList[rowIndex];
                  return (
                    <Button
                      size='small'
                      className='w-24'
                      color={item.mintable ? 'error' : 'primary'}
                      onClick={() => {
                        setIsOpenDialog(true);
                        setChosenDialog(item);
                      }}
                    >
                      {item.mintable ? 'Delist' : 'Whitelist'}
                    </Button>
                  );
                },
              },
            },
          ]}
          options={{
            ...EnhancedTable.options,
          }}
        />
      </Spinner>

      <Dialog open={isOpenDialog}>
        <div className='py-8 px-10'>
          <Typography variant='h4' className='mb-6'>
            {chosenDialog.mintable ? 'Remove' : 'Approve'} Creator
          </Typography>
          <Typography className='mb-10'>
            Are you sure you want to {chosenDialog.mintable ? 'remove' : 'approve'}{' '}
            <Typography variant='h6' component='span'>
              {chosenDialog.username}
            </Typography>
          </Typography>
          <DialogActions>
            <Button color='inherit' className='w-24' onClick={() => setIsOpenDialog(false)}>
              No
            </Button>
            <LoadingButton
              className='w-24'
              color={chosenDialog.mintable ? 'error' : 'primary'}
              loading={isLoadingConfirm}
              onClick={() =>
                updateUserQualified({
                  address: chosenDialog.address,
                  mintable: !chosenDialog.mintable,
                })
              }
            >
              Yes
            </LoadingButton>
          </DialogActions>
        </div>
      </Dialog>
    </Paper>
  );
};

export default ArtistApprove;
