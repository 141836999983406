import { store } from 'reducers';
import { ActionType } from 'reducers/coreui';

export const DARKMODE = 'darkmode';

const enqueueSnackbar = (message, { variant, key }) => {
  store.dispatch({
    type: ActionType.SEND_ALERT,
    data: { message, variant, key, onUpdate: Math.random() },
  });
};

const updateDarkmode = (darkmode) => {
  store.dispatch({
    type: ActionType.GET_DARKMODE,
    data: darkmode,
  });
  localStorage.setItem(DARKMODE, darkmode);
};

export const coreuiAction = {
  enqueueSnackbar,
  updateDarkmode,
};
