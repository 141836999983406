import { store } from 'reducers';
import { ActionType } from 'reducers/profile';

const signIn = (profile) => {
  store.dispatch({
    type: ActionType.USER_LOGIN,
    data: profile,
  });
};

const signOut = () => {
  store.dispatch({
    type: ActionType.USER_LOGOUT,
  });
};

export const profileAction = {
  signIn,
  signOut,
};
