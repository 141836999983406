import React from 'react';
import { Paper, Typography, Avatar, Box, Button } from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

function PopupDeList({ onClose }) {
  const classes = useStyles();
  const [value, setValue] = React.useState('1');

  const handleChange = (e, newValue) => {
    setValue(newValue);
  };
  return (
    <Paper className={`flex justify-between max-w-7xl ${classes.root}`}>
      <CloseIcon onClick={onClose} style={{ position: 'absolute', top: 32, right: 36, cursor: 'pointer' }} />
      <Box style={{ width: '44%' }}>
        <img
          className='w-full h-full'
          src='https://thuvientaichinh.com/wp-content/uploads/NFT-la-gi-Nhung-thong-tin-can-ma-ban-can-biet-3.jpg'
        />
      </Box>
      <Box style={{ width: '54%' }} className='flex flex-col'>
        <Typography component='p' className='text-3xl mb-5'>
          Sonya Atrëides #45
        </Typography>
        <Box className='flex mb-8'>
          <Avatar className='w-12 h-12 bg-yellow-600 text-5xl border-2 mr-2'></Avatar>
          <Box className='flex flex-col justify-center'>
            <Typography variant='h6' color='#949496'>
              Owner
            </Typography>
            <Typography variant='h5'>Mew</Typography>
          </Box>
        </Box>
        <Box className='flex-grow'>
          <TabContext className='flex flex-col' value={value}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <TabList
                classes={{ indicator: classes.indicator }}
                onChange={handleChange}
                aria-label='lab API tabs example'
              >
                <Tab classes={{ selected: classes.selected }} className='capitalize' label='Description' value='1' />
                <Tab classes={{ selected: classes.selected }} className='capitalize' label='History' value='2' />
              </TabList>
            </Box>
            <TabPanel className='px-0 pb-0' value='1'>
              <Box className='flex justify-between'>
                <Box className='flex mb-8 w-5/12'>
                  <Avatar className='w-12 h-12 bg-yellow-600 text-5xl border-2 mr-2'></Avatar>
                  <Box className='flex flex-col justify-center'>
                    <Typography variant='h6' color='#949496'>
                      Collection
                    </Typography>
                    <Typography variant='h5'>ChainGuardians</Typography>
                  </Box>
                </Box>
                <Box className='flex mb-8 w-5/12'>
                  <Avatar className='w-12 h-12 bg-yellow-600 text-5xl border-2 mr-2'></Avatar>
                  <Box className='flex flex-col justify-center'>
                    <Typography variant='h6' color='#949496'>
                      Creator
                    </Typography>
                    <Typography variant='h5'>CG</Typography>
                  </Box>
                </Box>
              </Box>
              <Typography variant='body1' className='mb-5 flex-grow'>
                One of the original androids created within the Chainguardians cryptoverse. Believed to be modelled on a
                powerful messiah, she is loyal, powerful, and rare servant. B100 is an remodelled version of the R100,
                stripping away her anti-thermal surface cladding replacing with advanced water tight materials, the B100
                designed to participate in search, rescue, and recover operations specialised in sub-terrain and under
                water operations.
              </Typography>
            </TabPanel>
            <TabPanel className='px-0 pb-0 overflow-y-auto' value='2'>
              <Box className='max-h-80'>
                {[1, 2, 3, 4].map((item, index) => (
                  <Box key={index} className='flex mb-5 pr-3'>
                    <Avatar className='w-12 h-12 bg-yellow-600 text-5xl border-2 mr-2'></Avatar>
                    <Box className='flex flex-col justify-center flex-grow'>
                      <Typography variant='h5' color='#242424'>
                        Purchased
                      </Typography>
                      <Typography variant='h6' color='#949496' className='text-xs'>
                        By @0x193...9afa at 20/08/2021, 18:15
                      </Typography>
                    </Box>
                    <Typography variant='h5' color='#242424'>
                      0.5 ETH
                    </Typography>
                  </Box>
                ))}
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
        <Button className={classes.btnDelist}>Delist</Button>
      </Box>
    </Paper>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: 1280,
    height: 630,
    position: 'relative',
    padding: '32px 36px',
    [theme.breakpoints.down('lg')]: {
      width: 992,
    },
    [theme.breakpoints.down('md')]: {
      width: 768,
    },
    [theme.breakpoints.down('sm')]: {
      width: 540,
    },
  },
  selected: {
    color: '#242424!important',
    '& .MuiListItemIcon-root': {
      color: '#FFFFFF!important',
    },
  },
  expanded: {
    margin: '0px!important',
    minHeight: '20px!important',
  },
  indicator: {
    backgroundColor: '#242424!important',
  },
  btnDelist: {
    background: '#FF5630!important',
    width: '96px',
    color: '#FFFFFF!important',
    borderRadius: '4px',
    position: 'absolute',
    bottom: 32,
    right: 36,
    cursor: 'pointer',
  },
}));

export default PopupDeList;
