import { createStore, combineReducers } from 'redux';
import coreui from './coreui';
import system from './system';
import profile from './profile';

export const store = createStore(
  combineReducers({
    coreui,
    system,
    profile,
  }),
  {},
);
