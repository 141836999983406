import React from 'react';
import { Button, Typography } from '@mui/material';
import { styled } from '@mui/styles';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

const ButtonCancel = styled(Button)({
  marginRight: '6px',
  minWidth: '130px',
  background: '#EBECF0!important',
});

const ButtonDelete = styled(ButtonCancel)({
  background: '#FF5630!important',
});

function PopupDelete({ onCancel, onConfirm }) {
  return (
    <div className='rounded-lg w-80 h-40 px-6 py-2'>
      <Typography variant='body1' className='my-2 text-xl flex items-center' style={{ color: '#FF5630' }}>
        <DeleteOutlinedIcon className='mr-1' /> Delete
      </Typography>
      <Typography variant='body2' style={{ color: '#7A7F9A' }} className='my-2 text-neutral-100 mb-6 text-sm'>
        Are you sure you want to delete this item?
      </Typography>
      <ButtonCancel onClick={onCancel}>Cancel</ButtonCancel>
      <ButtonDelete onClick={onConfirm}>Yes, continue</ButtonDelete>
    </div>
  );
}

export default PopupDelete;
