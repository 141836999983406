import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Paper, TextField, Typography } from '@mui/material';
import { FormControl, FormHelperText, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { profileAction } from 'actions/profile';
import { accountService } from 'services/account';

export const NETWORKS = [
  { code: '/v1', name: 'Ethereum', describe: 'Ethereum' },
  { code: '/v1/bsc', name: 'Binance', describe: 'Binance Smart Chain' },
];

const LoginForm = () => {
  const { control, handleSubmit } = useForm();
  const { mutate: login, isLoading } = useMutation(accountService.login, {
    onSuccess: (response, { api, username }) => {
      profileAction.signIn({ ...response, api, username });
    },
  });

  const handleClickLogin = () => {
    handleSubmit((values) => {
      login({ ...values });
    })();
  };

  return (
    <Paper className='flex flex-col py-10 px-16' style={{ width: 420 }}>
      <Typography variant='h2' color='primary' className='mb-2'>
        Login
      </Typography>
      <Typography className='text-neutral-100 mb-6'>Please login into your account</Typography>
      <Controller
        name='api'
        defaultValue={NETWORKS[0].code}
        control={control}
        render={({ field }) => (
          <ToggleButtonGroup fullWidth exclusive size='small' className='mb-4' {...field}>
            {NETWORKS.map((item, index) => (
              <ToggleButton key={index} value={item.code}>
                {item.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        )}
      />
      <Controller
        name='username'
        defaultValue=''
        control={control}
        rules={{ required: 'Username is required' }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth className='mb-4'>
            <label>Username</label>
            <TextField {...field} />
            <FormHelperText hidden={!invalid}>{error?.message}</FormHelperText>
          </FormControl>
        )}
      />
      <Controller
        name='password'
        defaultValue=''
        control={control}
        rules={{ required: 'Password is required' }}
        render={({ field, fieldState: { invalid, error } }) => (
          <FormControl fullWidth className='mb-10'>
            <label>Password</label>
            <TextField type='password' {...field} />
            <FormHelperText hidden={!invalid}>{error?.message}</FormHelperText>
          </FormControl>
        )}
      />

      <LoadingButton fullWidth size='large' loading={isLoading} onClick={handleClickLogin}>
        LOGIN
      </LoadingButton>
    </Paper>
  );
};

export default LoginForm;
