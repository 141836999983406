import React from 'react';
const useToggle = (open = false) => {
  const [isOpen, setIsOpen] = React.useState(open);

  const onToggle = React.useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return [isOpen, onToggle];
};

export default useToggle;
