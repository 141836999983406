import React from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { AuthLayout, PrivateLayout } from 'layouts';
import { AppController, AppTheme } from 'containers';
import { store } from 'reducers';
import { profileAction } from 'actions/profile';
import { queryClient } from 'services/client';
import { browserHistory } from 'utils/history';
import jwtDecode from 'jwt-decode';

const App = () => {
  const [isReady, setIsReady] = React.useState(false);

  React.useEffect(() => {
    try {
      const profile = JSON.parse(localStorage.getItem('profile'));
      jwtDecode(profile.token);
      profileAction.signIn(profile);
    } catch {
    } finally {
      setIsReady(true);
    }
  }, []);
 
  return (
    <ReduxProvider store={store}>
      <AppController>
        <QueryClientProvider client={queryClient}>
          <AppTheme>
            <Router history={browserHistory}>
              {isReady && (
                <Switch>
                  <Route path='/auth' component={AuthLayout} />
                  <Route path='/' component={PrivateLayout} />
                </Switch>
              )}
            </Router>
          </AppTheme>
        </QueryClientProvider>
      </AppController>
    </ReduxProvider>
  );
};

export default App;
