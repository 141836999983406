import { DateTime } from 'luxon';

const YYYYMMDD = 'yyyy-MM-dd';
const DDMMYYYYHHMM = 'dd/MM/yyyy HH:mm';

export const shorten = (address, before = 8, after = 6) => {
  if (address.length < 10) return address;
  return address.slice(0, before) + '...' + address.slice(address.length - after);
};

export const formatDay = (millis = Date.now(), format = YYYYMMDD) => {
  return DateTime.fromMillis(millis).toFormat(format);
};

export const formatTime = (second, format = DDMMYYYYHHMM) => {
  return DateTime.fromMillis(second * 1000).toFormat(format);
};
