export const ActionType = {
  SAVE_CATEGORIES: 'SAVE_CATEGORIES',
  SAVE_SUB_CATEGORIES: 'SAVE_SUB_CATEGORIES',
  SAVE_PAYMENTS: 'SAVE_PAYMENTS',
  SAVE_SYSTEM_CONFIG: 'SAVE_SYSTEM_CONFIG',
};

const system = (
  state = {
    categories: [],
    subCategories: [],
    payments: [],
    config: {},
  },
  { type, data },
) => {
  switch (type) {
    case ActionType.SAVE_CATEGORIES:
      return { ...state, categories: data };
    case ActionType.SAVE_SUB_CATEGORIES:
      return { ...state, subCategories: data };
    case ActionType.SAVE_PAYMENTS:
      return { ...state, payments: data };
    case ActionType.SAVE_SYSTEM_CONFIG:
      return { ...state, config: data };
    default:
      return state;
  }
};

export default system;
