import { client } from './axios';

const fetchContract = ({ offset, limit }) => client.get(`/v1/nft/contracts?offset=${offset}&limit=${limit}`);
const createContracts = (body) => client.post(`/v1/nft/contracts`, body);
const updateContracts = (address, body) => client.put(`/v1/nft/contracts/${address}`, body);
const deleteContracts = (address) => client.delete(`/v1/nft/contracts/${address}`);

export const contractService = {
  fetchContract,
  createContracts,
  updateContracts,
  deleteContracts,
};
