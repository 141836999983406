import React from 'react';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { FormControl, Grid, MenuItem, TextField } from '@mui/material';
import { DatePicker, LoadingButton } from '@mui/lab';
import { DateTime } from 'luxon';
import { useQuery } from 'react-query';
import { marketService } from 'services/market';

const TIME_RANGE_FILTERS = [
  { value: { days: 1 }, label: 'Last 24 hours' },
  { value: { days: 7 }, label: 'Last 7 days' },
  { value: { days: 30 }, label: 'Last 30 days' },
  { value: { years: 1 }, label: 'Last year' },
  { value: 0, label: 'Custom' },
];

const SALES_TYPE_FILTERS = [
  { value: ' ', label: 'All' },
  { value: '1', label: 'Primary Sale' },
  { value: '2', label: 'Secondary Sale' },
];

const WALLET_TYPE_FILTERS = [
  { value: ' ', label: 'All' },
  { value: '2', label: 'Treasure Wallet' },
  { value: '1', label: `By User` },
];

const SalesFilter = ({ onSearch }) => {
  const { payments } = useSelector(({ system }) => system);
  const { data: treasureWalet } = useQuery(['fetchTreasureWalet'], () => marketService.fetchTreasureWalet());

  const { control, handleSubmit, watch, setValue, clearErrors } = useForm({ mode: 'onChange' });

  React.useEffect(() => {
    const subscription = watch(({ walletType, timeRange }, { name, type }) => {
      if (type) {
        if (name === 'walletType') {
          if (walletType === WALLET_TYPE_FILTERS[1].value) {
            setValue('userAddr', treasureWalet);
          } else {
            setValue('userAddr', '');
          }
        }
        if (name === 'userAddr') {
          setValue('walletType', WALLET_TYPE_FILTERS[2].value);
        }

        if (name === 'timeRange') {
          if (timeRange) {
            setValue('startTime', DateTime.now().minus(timeRange));
            setValue('endTime', DateTime.now());
          }
        }
        if (name === 'startTime' || name === 'endTime') {
          setValue('timeRange', TIME_RANGE_FILTERS[4].value);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setValue, clearErrors, treasureWalet]);

  const handleClickSearch = React.useCallback(() => {
    handleSubmit((values) => {
      const { startTime, endTime, saleType, paymentTokenID, walletType, userAddr, timeRange } = values;
      onSearch({
        startTime: Math.floor(startTime?.valueOf() / 1000),
        endTime: timeRange === 0 ? Math.floor(endTime?.valueOf() / 1000) : undefined,
        saleType: saleType.trim(),
        paymentTokenID: paymentTokenID.trim(),
        salesSummaryAddressType: walletType.trim(),
        userAddr: userAddr.trim(),
      });
    })();
  }, [handleSubmit, onSearch]);

  React.useEffect(() => {
    handleClickSearch();
  }, [handleClickSearch]);

  return (
    <>
      <Grid container columns={30} spacing={3} mb={3}>
        <Grid item xl={6} md={10} xs={30}>
          <Controller
            name='timeRange'
            defaultValue={TIME_RANGE_FILTERS[2].value}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <label>Time range</label>
                <TextField select {...field}>
                  {TIME_RANGE_FILTERS.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xl={6} md={10} xs={30}>
          <Controller
            name='startTime'
            defaultValue={DateTime.now().minus(TIME_RANGE_FILTERS[2].value)}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <label>Start time</label>
                <DatePicker
                  {...field}
                  maxDate={DateTime.now()}
                  inputFormat='dd/MM/yyyy'
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xl={6} md={10} xs={30}>
          <Controller
            name='endTime'
            defaultValue={DateTime.now()}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <label>End time</label>
                <DatePicker
                  {...field}
                  maxDate={DateTime.now()}
                  inputFormat='dd/MM/yyyy'
                  renderInput={(params) => <TextField {...params} />}
                />
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xl={6} md={10} xs={30}>
          <Controller
            name='saleType'
            defaultValue={SALES_TYPE_FILTERS[0].value}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <label>Sale Type</label>
                <TextField select {...field}>
                  {SALES_TYPE_FILTERS.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xl={6} md={10} xs={30}>
          <Controller
            name='paymentTokenID'
            defaultValue=' '
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <label>Currency</label>
                <TextField select {...field}>
                  <MenuItem value=' '>All</MenuItem>
                  {payments.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.symbol}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>
      <Grid container columns={30} spacing={3} mb={3}>
        <Grid item xl={6} md={10} xs={30}>
          <Controller
            name='walletType'
            defaultValue={WALLET_TYPE_FILTERS[0].value}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <label>Wallet</label>
                <TextField select {...field}>
                  {WALLET_TYPE_FILTERS.map((item, index) => (
                    <MenuItem key={index} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
            )}
          />
        </Grid>
        <Grid item xl={12} md={20} xs={30}>
          <Controller
            name='userAddr'
            defaultValue=''
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <label>User Address</label>
                <TextField {...field} />
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      <LoadingButton onClick={handleClickSearch}>View Report</LoadingButton>
    </>
  );
};

export default SalesFilter;
