import React from 'react';
import { useMutation, useQuery } from 'react-query';
import { Copiable, Dropdown, EnhancedTable, Spinner } from 'components';
import { ExpandButton } from 'mui-datatables';
import { LoadingButton } from '@mui/lab';
import { Pagination, Paper, TableCell, TableRow, Tooltip } from '@mui/material';
import { FileDownloadOutlined } from '@mui/icons-material';
import { marketService } from 'services/market';
import { formatDay, formatTime, shorten } from 'utils/common';
import SalesFilter from './SalesFilter';

const SaleType = ({ saleType }) => {
  const isPrimary = saleType === 'PRIMARY';
  return (
    <span className={'font-medium rounded py-0.5 px-2 text-white ' + (isPrimary ? 'bg-red-400' : 'bg-blue-400')}>
      {saleType}
    </span>
  );
};

const SporesWallet = ({ item: { receiverAddr, saleType }, isSecondary }) => {
  const isSpores = isSecondary || saleType === 'PRIMARY';
  return isSpores ? (
    <Tooltip title={receiverAddr}>
      <span>Spores Wallet</span>
    </Tooltip>
  ) : (
    <Copiable address={receiverAddr} />
  );
};

const SalesInvoice = () => {
  const [dataSearch, setDataSearch] = React.useState({ perPage: 10 });

  const { data = {}, isFetching } = useQuery(
    ['marketService.searchSales', dataSearch],
    ({ queryKey }) => marketService.searchSales({ ...queryKey[1] }),
    { keepPreviousData: true, enabled: !!dataSearch.page },
  );
  const { salesSummary: dataList = [], page = 1, perPage = 10, totalCount = 0 } = data;

  const { mutate: downloadSales, isLoading: isLoadingExport } = useMutation(
    (body) => marketService.downloadSales({ ...dataSearch, ...body }),
    {
      onSuccess: ({ data }, { extension }) => {
        const link = document.createElement('a');
        const url = window.URL.createObjectURL(new Blob([data]));
        link.setAttribute('href', url);

        const user = shorten(dataSearch.userAddr) || 'Spores';
        const days = formatDay();
        link.setAttribute('download', `${user}-${days}` + extension);
        document.body.appendChild(link);
        link.click();
      },
    },
  );

  const handleChangeSearch = React.useCallback((search) => {
    setDataSearch((current) => ({
      ...current,
      page: 1,
      nonce: Math.floor(Math.random() * 1e6),
      ...search,
    }));
  }, []);

  return (
    <>
      <Paper className='p-6'>
        <SalesFilter onSearch={handleChangeSearch} />
      </Paper>

      <Paper className='mt-6 p-6'>
        <Spinner spinning={isFetching}>
          <EnhancedTable
            data={dataList}
            columns={[
              {
                name: 'timestamp',
                label: 'TIME',
                options: { customBodyRender: (value) => formatTime(value) },
              },
              {
                name: 'item',
                label: 'ITEM',
                options: { setCellProps: () => ({ className: 'font-medium' }) },
              },
              {
                name: 'saleType',
                label: 'SALE TYPE',
                options: { customBodyRender: (value) => <SaleType saleType={value} /> },
              },
              {
                name: 'purchaserAddr',
                label: 'PURCHASER',
                options: { customBodyRender: (value) => <Copiable address={value} /> },
              },
              {
                name: 'receiverAddr',
                label: 'RECEIVER',
                options: {
                  customBodyRender: (value, { rowIndex }) => <SporesWallet item={dataList[rowIndex]} />,
                },
              },
              { name: 'category', label: 'CATEGORY' },
              {
                name: 'itemPrice',
                label: 'PRICE',
                options: {
                  setCellHeaderProps: () => ({ className: 'text-right' }),
                  setCellProps: () => ({ className: 'text-right' }),
                },
              },
              {
                name: 'amount',
                label: 'AMOUNT',
                options: {
                  setCellHeaderProps: () => ({ className: 'text-right' }),
                  setCellProps: () => ({ className: 'text-right' }),
                },
              },
              { name: 'currency', label: 'CURRENCY' },
            ]}
            options={{
              ...EnhancedTable.options,
              expandableRows: true,
              renderExpandableRow: (value, { rowIndex }) => {
                const { salesSummaryDetail = [] } = dataList[rowIndex] ?? {};
                return salesSummaryDetail.map((item, index) => (
                  <TableRow key={index} className='bg-blue-50'>
                    <TableCell style={{ borderLeft: '4px solid var(--neutral-light-100)' }} />
                    <TableCell>{formatTime(item.timestamp)}</TableCell>
                    <TableCell className='font-medium'>{item.item}</TableCell>
                    <TableCell>
                      <SaleType saleType={item.saleType} />
                    </TableCell>
                    <TableCell>
                      <Copiable address={item.purchaserAddr} />
                    </TableCell>
                    <TableCell>
                      <SporesWallet item={item} isSecondary={index === 1} />
                    </TableCell>
                    <TableCell>{item.category}</TableCell>
                    <TableCell />
                    <TableCell className='text-right'>{item.amount}</TableCell>
                    <TableCell>{item.currency}</TableCell>
                  </TableRow>
                ));
              },
            }}
            components={{
              ExpandButton: ({ dataIndex, ...props }) => {
                const { salesSummaryDetail = [] } = dataList[dataIndex] ?? {};
                return salesSummaryDetail.length > 0 && <ExpandButton {...props} />;
              },
            }}
          />
        </Spinner>

        <div className='flex justify-between mt-6'>
          <div className='w-20' />
          <Pagination
            page={page}
            count={Math.ceil(totalCount / perPage)}
            onChange={(event, page) => handleChangeSearch({ page })}
          />

          <Dropdown
            trigger={
              <LoadingButton startIcon={<FileDownloadOutlined />} loading={isLoadingExport}>
                Export
              </LoadingButton>
            }
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Dropdown.Option onClick={() => downloadSales({ isExport: 1, export: 'excel', extension: '.xlsx' })}>
              EXCEL
            </Dropdown.Option>
            <Dropdown.Option onClick={() => downloadSales({ isExport: 1, export: 'pdf', extension: '.pdf' })}>
              PDF
            </Dropdown.Option>
            <Dropdown.Option onClick={() => downloadSales({ isExport: 1, export: 'csv', extension: '.csv' })}>
              CSV
            </Dropdown.Option>
          </Dropdown>
        </div>
      </Paper>
    </>
  );
};

export default SalesInvoice;
