import { LoginForm } from 'views/Login';

const authRoute = {
  login: {
    path: '/auth/login',
    component: LoginForm,
  },
};

export default authRoute;
