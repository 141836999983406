import { QueryClient } from 'react-query';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      staleTime: 1000 * 60 * 10,
      placeholderData: {},
      refetchOnMount: 'always',
    },
  },
});
