export const ActionType = {
  USER_LOGIN: 'USER_LOGIN',
  USER_LOGOUT: 'USER_LOGOUT',
};

const profile = (state = {}, { type, data }) => {
  switch (type) {
    case ActionType.USER_LOGIN:
      localStorage.setItem('profile', JSON.stringify(data));
      return { ...data, isLoggedIn: true };
    case ActionType.USER_LOGOUT:
      localStorage.removeItem('profile');
      return { isLoggedIn: false };
    default:
      return state;
  }
};

export default profile;
