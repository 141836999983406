import { store } from 'reducers';
import { ActionType } from 'reducers/system';

const saveCategories = ({ categories }) => {
  store.dispatch({
    type: ActionType.SAVE_CATEGORIES,
    data: categories,
  });
};

const saveSubCategories = ({ subCategories }) => {
  store.dispatch({
    type: ActionType.SAVE_SUB_CATEGORIES,
    data: subCategories,
  });
};

const savePayments = ({ paymentTokens }) => {
  store.dispatch({
    type: ActionType.SAVE_PAYMENTS,
    data: paymentTokens,
  });
};

const saveSystemConfig = ({ data: config }) => {
  store.dispatch({
    type: ActionType.SAVE_SYSTEM_CONFIG,
    data: config,
  });
};

export const systemAction = {
  saveCategories,
  saveSubCategories,
  savePayments,
  saveSystemConfig,
};
