import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Breadcrumbs, Toolbar, Typography } from '@mui/material';
import { HomeOutlined } from '@mui/icons-material';
import { privateRoute } from 'routes';
import { NETWORKS } from 'views/Login/LoginForm';

const Header = () => {
  const location = useLocation();
  const { api } = useSelector(({ profile }) => profile);

  const route = Object.values(privateRoute).find((item) => location.pathname === item.path);

  return (
    <Toolbar color='inherit' className='App-Header'>
      <div>
        <Breadcrumbs className='mb-3'>
          <Link to={privateRoute.home.path}>
            <HomeOutlined />
          </Link>
          {route?.path
            .split('/')
            .slice(1)
            .map((item) => (
              <Link key={item} to={location.pathname}>
                {Object.values(privateRoute).find((items) => items.path.includes(item))?.name}
              </Link>
            ))}
        </Breadcrumbs>
        <Typography variant='h3' className='text-neutral-900 mb-2'>
          {route?.name}
        </Typography>
        <Typography variant='h6' className='text-neutral-100'>
          {route?.title}
        </Typography>
      </div>

      <div className='flex-1' />
      <div className='self-start'>
        <Typography variant='h4'>{NETWORKS.find((item) => item.code === api)?.describe}</Typography>
      </div>
    </Toolbar>
  );
};

export default Header;
