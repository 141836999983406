import React from 'react';
import PropTypes from 'prop-types';
import { Menu, MenuItem } from '@mui/material';

/**
 * @typedef { import("@mui/material").MenuProps } MenuProps
 */

/**
 * @param {MenuProps} props
 */
const Dropdown = ({ trigger, ...props }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <span onClick={handleClick}>{trigger}</span>
      <Menu anchorEl={anchorEl} open={open} onClose={handleClose} onClick={handleClose} {...props} />
    </>
  );
};

Dropdown.Option = MenuItem;

Dropdown.propTypes = {
  trigger: PropTypes.any.isRequired,
};

export default Dropdown;
