import { client } from './axios';

const login = ({ api = '/v1', ...body }) => client.post(`${api}/account/admin/auth/login`, body);

const countUser = () => client.get(`/v1/account/users/users-count`);
const countCreator = () => client.get(`/v1/account/users/creators-count`);

const searchUser = (params) => client.get(`/v1/account/users/creators`, { params });
const updateUserNote = ({ id, ...body }) => client.put(`/v1/account/users/${id}/note`, body);
const updateUserQualified = (body) => client.put(`/v1/account/users/qualified`, body);

export const accountService = {
  login,

  countUser,
  countCreator,

  searchUser,
  updateUserNote,
  updateUserQualified,
};
