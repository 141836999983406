export const ActionType = {
  SEND_ALERT: 'SEND_ALERT',
  GET_DARKMODE: 'GET_DARKMODE',
};

const coreui = (
  state = {
    alert: {},
    darkmode: null,
  },
  { type, data },
) => {
  switch (type) {
    case ActionType.SEND_ALERT:
      return { ...state, alert: data };
    case ActionType.GET_DARKMODE:
      return { ...state, darkmode: data };
    default:
      return state;
  }
};

export default coreui;
