import MUIDataTable from 'mui-datatables';

const EnhancedTable = MUIDataTable;

EnhancedTable.options = {
  elevation: 0,
  pagination: false,
  search: false,
  download: false,
  print: false,
  filter: false,
  viewColumns: false,
  sort: false,
  fixedSelectColumn: false,
  selectableRows: 'none',
  expandableRows: false,
  expandableRowsHeader: false,
};

export default EnhancedTable;
