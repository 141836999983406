import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { privateRoute } from 'routes';
import { ContractWhislist } from '../ContractWhitelist';
import { ArtistCreatorWhitelist } from '../ArtistCreatorWhitelist';
import { DeListNFT } from '../DeListNFT';

function ImportNFT() {
  return (
    <Switch>
      <Route path={privateRoute.contractWhitelist.path} component={ContractWhislist} />
      <Route path={privateRoute.artistCreatorWhitelist.path} component={ArtistCreatorWhitelist} />
      <Route path={privateRoute.deListNFT.path} component={DeListNFT} />
      <Redirect from='/' to={privateRoute.contractWhitelist.path} />
    </Switch>
  );
}

export default ImportNFT;
