import { SnackbarProvider } from 'notistack';

const Controller = ({ children }) => {
  return (
    <SnackbarProvider preventDuplicate={false} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
      {children}
    </SnackbarProvider>
  );
};

export default Controller;
